import { MODE_FIELD } from '../../components/KendoDataGrid/constants';

export const EMPTY_VALUE_PLACEHOLDER = '-';
export const FIELDS_TO_OMIT = [MODE_FIELD];

export const SUPPLIER_KEYS = {
  SUPPLIER_CONTACTS_KEY: 'supplier-contacts',
  SUPPLIER_PRODUCT_SERVICE_PROVIDED_KEY: 'supplier-offerings',
  SUPPLIER_SUPPORTING_DOCS_KEY: 'supplier-supporting-docs',
  SUPPLIER_REFERENCES_KEY: 'supplier-references',
};
