import { HelpModule } from '../../../../App/Enlil/Features/HelpCenter/HelpCenter.types';
import { FBSchemaProps } from '../../../../ui/form.builder';
import { Translation } from '../../../../ui/translations/types';
import { GroupTag } from '../../auth/types';
import { Nullable } from '../../common/types';
import { DocumentRevisionStatus, FBFormTemplateProps } from '../types';
import { DOCUMENT_TYPE_BY_ID, DOCUMENT_TYPE_LIST, SET_DOCUMENT_TYPES_BY_CATEGORY, TAB_CONFIGURATION_LIST } from './constants';

export enum REVISION_STAGE_OPTIONS {
  NUMERIC = 'common.Numeric',
  ALPHA = 'common.Alpha',
}

// Copied from BE repo: src/modules/documentTypes/documentType.entity.ts
export enum DOC_TYPE_GROUP {
  FORM = 'FORM',
  OTHER = 'OTHER',
  PI = 'PI',
  RECORD = 'RECORD',
  CHANGE_REQUEST = 'CHANGE_REQUEST',
  DAM = 'DAM',
  DYNAMIC_FORM = 'DYNAMIC_FORM',
  POAM = 'POAM',
  PO = 'PO',
  SUPPLIER = 'SUPPLIER',
  PART = 'PART',
  MATERIAL = 'MATERIAL',
  RECEIVABLE = 'RECEIVABLE',
  ENGINEERING_BUILD = 'ENGINEERING_BUILD',
  EQUIPMENT = 'EQUIPMENT',
  EQUIPMENT_SPECIFICATION = 'EQUIPMENT_SPECIFICATION',
  MAINTENANCE_RECORD = 'MAINTENANCE_RECORD',
  FAI_INSPECTION = 'FAI_INSPECTION',
  LOT = 'LOT',
  PART_LOT = 'PART_LOT',
  NEW_COMPANY = 'NEW_COMPANY',
  SEED_DOCUMENT = 'SEED_DOCUMENT',
  LHR = 'LHR',
  PAPER_LHR = 'PAPER_LHR',
  LHRT = 'LHRT',
  PI_INSTANCE = 'PI_INSTANCE',
  TRAINING_CURRICULUM = 'TRAINING_CURRICULUM',
  BULK_CREATE = 'BULK_CREATE',
  CONFIG_OPTIONS = 'CONFIG_OPTIONS',
  WORK_ORDER = 'WORK_ORDER',
  GROUP_TRAINING = 'GROUP_TRAINING',
  CUSTOMERS = 'CUSTOMERS',
  SHIPPING = 'SHIPPING',
}

export type DocumentGroupType = keyof typeof DOC_TYPE_GROUP;

// Copied from BE repo: src/modules/documentTypes/documentType.entity.ts
export enum DOC_TYPE_GROUP_OPTION {
  FORM = 'FORM',
  DAM = 'DAM', // this can only go to DAM group
  CAN_BE_OUTPUT = 'CAN_BE_OUTPUT',
  MUST_BE_OUTPUT = 'MUST_BE_OUTPUT',
  HAVE_OUTPUT = 'HAVE_OUTPUT',
  HAVE_HIDDEN_OUTPUT = 'HAVE_HIDDEN_OUTPUT',
  RECORD = 'RECORD',
  CHANGE_REQUEST = 'CHANGE_REQUEST',
  ONE_ITEM = 'ONE_ITEM',
  DYNAMIC_FORM = 'DYNAMIC_FORM',
  OTHER = 'OTHER',
  POAM = 'POAM',
  PO = 'PO',
  MATERIAL = 'MATERIAL',
  PART = 'PART',
  SUPPLIER = 'SUPPLIER',
  RECEIVABLE = 'RECEIVABLE',
  CAN_BE_COPIED = 'CAN_BE_COPIED',
  EQUIPMENT = 'EQUIPMENT',
  EQUIPMENT_SPECIFICATION = 'EQUIPMENT_SPECIFICATION',
  MAINTENANCE = 'MAINTENANCE',
  DOCUMENT = 'DOCUMENT',
  EDITABLE_SCHEMA = 'EDITABLE_SCHEMA',
  ENABLE_SCHEMA_INHERITANCE = 'ENABLE_SCHEMA_INHERITANCE',
  FAI_INSPECTION = 'FAI_INSPECTION',
  CAN_CREATE_INSPECTION = 'CAN_CREATE_INSPECTION',
  SERIALIZED_CSV = 'SERIALIZED_CSV',
  ENGINEERING_BUILD = 'ENGINEERING_BUILD',
  OWNED_INPUT = 'OWNED_INPUT',
  LOT = 'LOT',
  GENERATE_LOT_ON_DOC_REV_CREATE = 'GENERATE_LOT_ON_DOC_REV_CREATE',
  PART_LOT = 'PART_LOT',
  COMPANY_BOOTSTRAP_ON_RELEASE = 'COMPANY_BOOTSTRAP_ON_RELEASE',
  SEED = 'SEED',
  MPI = 'MPI',
  MPI_OUTPUT = 'MPI_OUTPUT',
  BASE_LHR = 'BASE_LHR',
  ENLIL_LHR = 'ENLIL_LHR',
  PAPER_LHR = 'PAPER_LHR',
  FB_DEFAULT_NOTES = 'FB_DEFAULT_NOTES',
  OPERATOR_RELEASE = 'OPERATOR_RELEASE',
  REDLINE = 'REDLINE',
  EXPORTABLE = 'EXPORTABLE',
  DIRECT_APPROVAL = 'DIRECT_APPROVAL',
  ONLY_ON_OUTPUTS_DROPDOWN = 'ONLY_ON_OUTPUTS_DROPDOWN',
  TRAINING_CURRICULUM = 'TRAINING_CURRICULUM',
  BULK_CREATE = 'BULK_CREATE',
  CONFIG_OPTIONS = 'CONFIG_OPTIONS',
  INPUT_OWNERS_APPROVERS = 'INPUT_OWNERS_APPROVERS',
  LHRT = 'LHRT',
  EMAILS_RECORD = 'EMAILS_RECORD',
  EMAILS_LHR = 'EMAILS_LHR',
  EMAILS_LHRS = 'EMAILS_LHRS',
  EMAILS_PO = 'EMAILS_PO',
  WORK_ORDER = 'WORK_ORDER',
  GROUP_TRAINING = 'GROUP_TRAINING',
  CUSTOMERS = 'CUSTOMERS',
  SHIPPING = 'SHIPPING',

  TABS_MPI = 'TABS_MPI',
  TABS_MPI_INSTANCE = 'TABS_MPI_INSTANCE',
  TABS_LHR = 'TABS_LHR',
  TABS_PAPER_LHR = 'TABS_PAPER_LHR',
  TABS_LHRT = 'TABS_LHRT',
  TABS_EQUIPMENT = 'TABS_EQUIPMENT',
  TABS_OTHER = 'TABS_OTHER',
  TABS_PO = 'TABS_PO',
  TABS_POAM = 'TABS_POAM',
  TABS_BULK_CREATE = 'TABS_BULK_CREATE',
  TABS_TRAINING_CURRICULUM = 'TABS_TRAINING_CURRICULUM',
  TABS_DAM = 'TABS_DAM',
  TABS_RECEIVING = 'TABS_RECEIVING',
  TABS_LOT = 'TABS_LOT',
  TABS_ENGINEERING_BUILD = 'TABS_ENGINEERING_BUILD',
  TABS_SUPPLIER = 'TABS_SUPPLIER',
  TABS_APPROVAL_REQUEST = 'TABS_APPROVAL_REQUEST',
  TABS_PARTS = 'TABS_PARTS',
  TABS_RECORD = 'TABS_RECORD',
  TABS_EQUIPMENT_SPECIFICATION = 'TABS_EQUIPMENT_SPECIFICATION',
  TABS_PART_LOT = 'TABS_PART_LOT',
  TABS_NEW_COMPANY = 'TABS_NEW_COMPANY',
  TABS_MAINTENANCE_RECORD = 'TABS_MAINTENANCE_RECORD',
  TABS_DYNAMIC_FORM = 'TABS_DYNAMIC_FORM',
  TABS_SEED = 'TABS_SEED',
  TABS_CONFIG_OPTIONS = 'TABS_CONFIG_OPTIONS',
  TABS_WO = 'TABS_WO',
  TABS_GROUP_TRAINING = 'TABS_GROUP_TRAINING',
  TABS_CUSTOMERS = 'TABS_CUSTOMERS',
  TABS_SHIPPING = 'TABS_SHIPPING',
}
export type DocumentGroupOptionsType = keyof typeof DOC_TYPE_GROUP_OPTION;
export enum TabTypeOptions {
  TABS_MEQ = 'MEQ',
  DYNAMIC = 'DYNAMIC',
  TRAINING = 'TRAINING',
  BOM = 'BOM',
  MIXED = 'MIXED',
  ITEMS = 'ITEMS',
  MATERIAL_DISPOSITION='MATERIAL_DISPOSITION',
  SUMMARY='SUMMARY',
  ALLOCATION = 'ALLOCATION',
}

export enum CATEGORY {
  WORKORDER_CATEGORY = 'Work Order',
}

export enum WorkOrderTypes {
  MAKE_KIT = 'make_kit',
  MAKE_PRODUCT = 'make_product_or_part',
  OTHER = 'other',
}

export enum WorkOrderTypesValues {
  make_kit = 'Make Kit',
  make_product_or_part = 'Make Product or Part',
  other = 'Other',
}

export const WO_LHR_TABLE_KEY_NAME = 'lhrIds';
export const WO_PART_KEY_NAME = 'wo_part';
export const WO_PART_QTY_KEY_NAME = 'wo_part_quantity';
export const WO_PART_UNIT_KEY_NAME = 'wo_part_unit';
export const WO_LOT_ID_KEY_NAME = 'generated_lot_id';
export const WO_TYPE_KEY = 'work_order_type';

// Copied from BE repo: src/modules/documentTypes/documentType.entity.ts
export const GroupWithGroupOptions = {
  FORM: [
    DOC_TYPE_GROUP_OPTION.FORM,
    DOC_TYPE_GROUP_OPTION.HAVE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.TABS_OTHER,
  ],
  LHRT: [
    DOC_TYPE_GROUP_OPTION.FORM,
    DOC_TYPE_GROUP_OPTION.HAVE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.LHRT,
    DOC_TYPE_GROUP_OPTION.TABS_LHRT,
  ],
  OTHER: [
    DOC_TYPE_GROUP_OPTION.CAN_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.DOCUMENT,
    DOC_TYPE_GROUP_OPTION.OTHER,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.TABS_OTHER,
  ],
  CONFIG_OPTIONS: [
    DOC_TYPE_GROUP_OPTION.MUST_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.ONE_ITEM,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.CONFIG_OPTIONS,
    DOC_TYPE_GROUP_OPTION.TABS_CONFIG_OPTIONS,
  ],
  MATERIAL: [
    DOC_TYPE_GROUP_OPTION.CAN_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.DOCUMENT,
    DOC_TYPE_GROUP_OPTION.MATERIAL,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.TABS_PARTS,
  ],
  ENGINEERING_BUILD: [
    DOC_TYPE_GROUP_OPTION.CAN_BE_COPIED,
    DOC_TYPE_GROUP_OPTION.CAN_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.RECORD,
    DOC_TYPE_GROUP_OPTION.EDITABLE_SCHEMA,
    DOC_TYPE_GROUP_OPTION.ENGINEERING_BUILD,
    DOC_TYPE_GROUP_OPTION.DIRECT_APPROVAL,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.EMAILS_RECORD,
    DOC_TYPE_GROUP_OPTION.TABS_ENGINEERING_BUILD,
  ],
  PI: [
    DOC_TYPE_GROUP_OPTION.FORM,
    DOC_TYPE_GROUP_OPTION.HAVE_HIDDEN_OUTPUT,
    DOC_TYPE_GROUP_OPTION.MPI,
    DOC_TYPE_GROUP_OPTION.FB_DEFAULT_NOTES,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.TABS_MPI,
  ],
  PI_INSTANCE: [
    DOC_TYPE_GROUP_OPTION.MUST_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.RECORD,
    DOC_TYPE_GROUP_OPTION.MPI_OUTPUT,
    DOC_TYPE_GROUP_OPTION.FB_DEFAULT_NOTES,
    DOC_TYPE_GROUP_OPTION.OPERATOR_RELEASE,
    DOC_TYPE_GROUP_OPTION.REDLINE,
    DOC_TYPE_GROUP_OPTION.ENABLE_SCHEMA_INHERITANCE,
    DOC_TYPE_GROUP_OPTION.DIRECT_APPROVAL,
    DOC_TYPE_GROUP_OPTION.EMAILS_LHRS,
    DOC_TYPE_GROUP_OPTION.TABS_MPI_INSTANCE,
  ],
  RECORD: [
    DOC_TYPE_GROUP_OPTION.CAN_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.RECORD,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.DIRECT_APPROVAL,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.EMAILS_RECORD,
    DOC_TYPE_GROUP_OPTION.TABS_RECORD,
  ],
  DAM: [
    DOC_TYPE_GROUP_OPTION.MUST_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.ONE_ITEM,
    DOC_TYPE_GROUP_OPTION.DAM,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.TABS_DAM,
  ],
  DYNAMIC_FORM: [
    DOC_TYPE_GROUP_OPTION.FORM,
    DOC_TYPE_GROUP_OPTION.DYNAMIC_FORM,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.TABS_DYNAMIC_FORM,
  ],
  POAM: [
    DOC_TYPE_GROUP_OPTION.MUST_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.ONE_ITEM,
    DOC_TYPE_GROUP_OPTION.POAM,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.TABS_POAM,
  ],
  PO: [
    DOC_TYPE_GROUP_OPTION.CAN_BE_COPIED,
    DOC_TYPE_GROUP_OPTION.MUST_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.PO,
    DOC_TYPE_GROUP_OPTION.DIRECT_APPROVAL,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.EMAILS_PO,
    DOC_TYPE_GROUP_OPTION.TABS_PO,
  ],
  CHANGE_REQUEST: [
    DOC_TYPE_GROUP_OPTION.CHANGE_REQUEST,
    DOC_TYPE_GROUP_OPTION.MUST_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.TABS_APPROVAL_REQUEST,
  ],
  SUPPLIER: [
    DOC_TYPE_GROUP_OPTION.CAN_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.SUPPLIER,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.DIRECT_APPROVAL,
    DOC_TYPE_GROUP_OPTION.TABS_SUPPLIER,
  ],
  PART: [
    DOC_TYPE_GROUP_OPTION.CAN_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.PART,
    DOC_TYPE_GROUP_OPTION.MATERIAL,
    DOC_TYPE_GROUP_OPTION.EDITABLE_SCHEMA,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.TABS_PARTS,
  ],
  RECEIVABLE: [
    DOC_TYPE_GROUP_OPTION.RECEIVABLE,
    DOC_TYPE_GROUP_OPTION.CAN_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.DIRECT_APPROVAL,
    DOC_TYPE_GROUP_OPTION.RECORD,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.EMAILS_RECORD,
    DOC_TYPE_GROUP_OPTION.TABS_RECEIVING,
  ],
  EQUIPMENT: [
    DOC_TYPE_GROUP_OPTION.CAN_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.EQUIPMENT,
    DOC_TYPE_GROUP_OPTION.EDITABLE_SCHEMA,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.TABS_EQUIPMENT,
    DOC_TYPE_GROUP_OPTION.DIRECT_APPROVAL,
  ],
  EQUIPMENT_SPECIFICATION: [
    DOC_TYPE_GROUP_OPTION.CAN_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.EDITABLE_SCHEMA,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.EQUIPMENT_SPECIFICATION,
    DOC_TYPE_GROUP_OPTION.DOCUMENT,
    DOC_TYPE_GROUP_OPTION.TABS_EQUIPMENT_SPECIFICATION,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
  ],
  MAINTENANCE_RECORD: [
    DOC_TYPE_GROUP_OPTION.CAN_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.RECORD,
    DOC_TYPE_GROUP_OPTION.MAINTENANCE,
    DOC_TYPE_GROUP_OPTION.DIRECT_APPROVAL,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.EMAILS_RECORD,
    DOC_TYPE_GROUP_OPTION.TABS_MAINTENANCE_RECORD,
  ],
  FAI_INSPECTION: [
    DOC_TYPE_GROUP_OPTION.CAN_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.RECORD,
    DOC_TYPE_GROUP_OPTION.FAI_INSPECTION,
    DOC_TYPE_GROUP_OPTION.SERIALIZED_CSV,
    DOC_TYPE_GROUP_OPTION.DIRECT_APPROVAL,
    DOC_TYPE_GROUP_OPTION.EMAILS_RECORD,
    DOC_TYPE_GROUP_OPTION.TABS_OTHER,
  ],
  LOT: [
    DOC_TYPE_GROUP_OPTION.CAN_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.RECORD,
    DOC_TYPE_GROUP_OPTION.LOT,
    DOC_TYPE_GROUP_OPTION.SERIALIZED_CSV,
    DOC_TYPE_GROUP_OPTION.ENABLE_SCHEMA_INHERITANCE,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.DIRECT_APPROVAL,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.EMAILS_RECORD,
    DOC_TYPE_GROUP_OPTION.TABS_LOT,
  ],
  PART_LOT: [
    DOC_TYPE_GROUP_OPTION.CAN_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.RECORD,
    DOC_TYPE_GROUP_OPTION.CAN_CREATE_INSPECTION,
    DOC_TYPE_GROUP_OPTION.LOT,
    DOC_TYPE_GROUP_OPTION.SERIALIZED_CSV,
    DOC_TYPE_GROUP_OPTION.ENABLE_SCHEMA_INHERITANCE,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.DIRECT_APPROVAL,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.EMAILS_RECORD,
    DOC_TYPE_GROUP_OPTION.TABS_PART_LOT,
  ],
  NEW_COMPANY: [
    DOC_TYPE_GROUP_OPTION.CAN_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.DOCUMENT,
    DOC_TYPE_GROUP_OPTION.COMPANY_BOOTSTRAP_ON_RELEASE,
    DOC_TYPE_GROUP_OPTION.TABS_NEW_COMPANY,
  ],
  SEED_DOCUMENT: [
    DOC_TYPE_GROUP_OPTION.CAN_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.DOCUMENT,
    DOC_TYPE_GROUP_OPTION.SEED,
    DOC_TYPE_GROUP_OPTION.TABS_SEED,
  ],
  LHR: [
    DOC_TYPE_GROUP_OPTION.BASE_LHR,
    DOC_TYPE_GROUP_OPTION.ENLIL_LHR,
    DOC_TYPE_GROUP_OPTION.RECORD,
    DOC_TYPE_GROUP_OPTION.OWNED_INPUT,
    DOC_TYPE_GROUP_OPTION.MUST_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.GENERATE_LOT_ON_DOC_REV_CREATE,
    DOC_TYPE_GROUP_OPTION.DIRECT_APPROVAL,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.OPERATOR_RELEASE,
    DOC_TYPE_GROUP_OPTION.EMAILS_LHR,
    DOC_TYPE_GROUP_OPTION.TABS_LHR,
  ],
  PAPER_LHR: [
    DOC_TYPE_GROUP_OPTION.BASE_LHR,
    DOC_TYPE_GROUP_OPTION.PAPER_LHR,
    DOC_TYPE_GROUP_OPTION.RECORD,
    DOC_TYPE_GROUP_OPTION.OWNED_INPUT,
    DOC_TYPE_GROUP_OPTION.MUST_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.GENERATE_LOT_ON_DOC_REV_CREATE,
    DOC_TYPE_GROUP_OPTION.DIRECT_APPROVAL,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.OPERATOR_RELEASE,
    DOC_TYPE_GROUP_OPTION.EMAILS_LHR,
    DOC_TYPE_GROUP_OPTION.TABS_PAPER_LHR,
  ],
  TRAINING_CURRICULUM: [
    DOC_TYPE_GROUP_OPTION.MUST_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.DIRECT_APPROVAL,
    DOC_TYPE_GROUP_OPTION.TRAINING_CURRICULUM,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.TABS_TRAINING_CURRICULUM,
  ],
  BULK_CREATE: [
    DOC_TYPE_GROUP_OPTION.MUST_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.RECORD,
    DOC_TYPE_GROUP_OPTION.BULK_CREATE,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.DIRECT_APPROVAL,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.EMAILS_RECORD,
    DOC_TYPE_GROUP_OPTION.TABS_BULK_CREATE,
  ],
  WORK_ORDER: [
    DOC_TYPE_GROUP_OPTION.MUST_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.WORK_ORDER,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.DIRECT_APPROVAL,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.TABS_WO,
  ],
  GROUP_TRAINING: [
    DOC_TYPE_GROUP_OPTION.CAN_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.GROUP_TRAINING,
    DOC_TYPE_GROUP_OPTION.TABS_GROUP_TRAINING,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
  ],
  CUSTOMERS: [
    DOC_TYPE_GROUP_OPTION.CAN_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.CUSTOMERS,
    DOC_TYPE_GROUP_OPTION.SERIALIZED_CSV,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.DIRECT_APPROVAL,
    DOC_TYPE_GROUP_OPTION.TABS_CUSTOMERS,
  ],
  SHIPPING: [
    DOC_TYPE_GROUP_OPTION.CAN_BE_OUTPUT,
    DOC_TYPE_GROUP_OPTION.SHIPPING,
    DOC_TYPE_GROUP_OPTION.SERIALIZED_CSV,
    DOC_TYPE_GROUP_OPTION.EXPORTABLE,
    DOC_TYPE_GROUP_OPTION.ONLY_ON_OUTPUTS_DROPDOWN,
    DOC_TYPE_GROUP_OPTION.DIRECT_APPROVAL,
    DOC_TYPE_GROUP_OPTION.TABS_SHIPPING,
  ],
};

interface PageDetailsConfig {
  helpModule?: HelpModule
}

export interface PageDetailsColumn {
  label: Translation
  sourcePath: string
  valuePath: string
  showOnWeb: boolean
  showOnAndroid: boolean
}

export interface PageDetails {
  id: string
  addNewBtnLabel: string
  columns: PageDetailsColumn[]
  config: PageDetailsConfig
  category: string
  exportList: boolean
}

export interface DocumentType {
  hiddenForCreationForUsersWithoutTagsFormatted?: string
  id: string
  documentTypeAcronym: string
  documentTypeName: string
  group: DocumentGroupType
  groupOptions?: DocumentGroupOptionsType[]
  pageDetails?: Nullable<PageDetails>
  isQms: boolean
  defaultTraining: boolean
  schema?: FBSchemaProps[]
  fieldsConfig?: FieldConfig
  defaultRevStage?: string
  hiddenForCreationForUsersWithoutTags?: GroupTag[]
  privateNumberPool?: boolean
  canRedLine?: boolean
  needsSignature?: boolean
  startingNumber?: string
  category?: string
  active?: boolean
  equipmentEnforceProd?: boolean
}

interface DocumentForms {
  id: string
  docId?: string
  documentType?: DocumentType
}

export interface DocumentTypeByIdForm {
  name: string
  id: string
  document: DocumentForms
  formInput?: Record<string, any>
  formTemplate?: FBFormTemplateProps
}

export interface DocumentTypeById {
  documentType: DocumentType
  forms: DocumentTypeByIdForm[]
}

export interface DocumentTypeByIdAction {
  type: typeof DOCUMENT_TYPE_BY_ID
  payload: DocumentTypeById
}

export interface TabConfigurationListAction {
  type: typeof TAB_CONFIGURATION_LIST
  payload: TabConfiguration[]
}

export interface DocumentTypeListAction {
  type: typeof DOCUMENT_TYPE_LIST
  payload: DocumentType[]
}

export interface DocumentTypeByCategoryAction {
  type: typeof SET_DOCUMENT_TYPES_BY_CATEGORY
  payload: DocumentType[]
}

export interface DocumentTypeByIdState {
  [key: string]: DocumentType
}

type TabType = 'MIXED' | 'DYNAMIC' | 'TRAINING' | 'BOM'| 'MEQ' | 'ITEMS' | 'MATERIAL_DISPOSITION' | 'ALLOCATION' | 'SUMMARY';

export interface TabInfo {
  labelId: string
  tabId: string
  type: TabType
}

export interface TabConfiguration {
  [key: string]: TabInfo[]
}

export type DocRevByIdState = DocumentTypeById;

export type DocumentTypeAllIdsState = string[];

export type DocumentTypeByCategoryState = DocumentType[];

export type TabConfigurationState = TabConfiguration[];

type FieldConfig = { [key in FieldType]?: IFieldConfigItem };

interface IFieldConfigItem {
  visible: boolean
  required?: boolean
  label?: string
  tooltipConfig?: TooltipLabelConfig
}

export interface TooltipLabelConfig {
  labelName: string
  defaultLabel?: string
  overriddenLabel: string
  visibilityFlag?: string
  requiredFlag?: string
}

// Copied from BE repo: src/modules/documentTypes/interfaces/fieldConfig.interface.ts
export enum InputFieldType{
  TITLE = 'C',
  DESCRIPTION = 'D',
  DOC_TYPE = 'E',
  DOC_ID = 'F',
  TRAINING = 'G',
  ATTACHMENTS = 'H',
  REVISION_CHANGE_SELECTION = 'M',
  NEXT_REVISION = 'I',
  NEXT_REVISION_STAGE = 'J',
  OBSOLETE = 'K',
  ADM_CHANGE = 'L',
  OPERATORS = 'U',
  REVISION_SELECTION = 'W',
}

// Copied from BE repo: src/modules/documentTypes/interfaces/fieldConfig.interface.ts
export enum DisplayFieldType{
  STATUS = 'A',
  STATUS_DRAFT = 'A1',
  STATUS_OBSOLETE = 'A2',
  STATUS_DEPRECATED = 'A3',
  STATUS_RELEASED = 'A4',
  STATUS_IN_REVIEW = 'A5',
  STATUS_PENDING_CHANGE = 'A6',
  STATUS_APPROVED = 'A7',
  STATUS_SUBMITTED ='UNUSED',
  STATUS_CANCELED = 'A8',
  STATUS_VOIDED = 'A9',
  DOC_ID = 'B',
  APPROVE_RELEASE_BTN = 'N',
  VERSIONS = 'O',
  VERSION = 'T',
  VERSION_DRAFT = 'P1',
  VERSION_OBSOLETE = 'P2',
  VERSION_DEPRECATED = 'P3',
  VERSION_RELEASED = 'P4',
  VERSION_IN_REVIEW = 'P5',
  VERSION_PENDING_CHANGE = 'P6',
  VERSION_APPROVED = 'P7',
  VERSION_SUBMITTED ='UNUSED',
  VERSION_CANCELED = 'P8',
  VERSION_VOIDED = 'P9',
  CR_ID = 'R',
  REVISE_BTN = 'S',
  BOOKMARKS = 'Q',
  ADD_NEW_BTN = 'V',
  UPDATE_NEW_BTN = 'V1',
  UNDO_BTN = 'V2',
}

export type FieldType = InputFieldType | DisplayFieldType;

export interface DocRevQueryParam {
  offset: number
  limit: number
  searchText: Nullable<string>
  fields: 'name,document.docId'
  status: DocumentRevisionStatus[]
}

export enum tabIdByInternalType {
  PAPER_LHR = 'details',
  LOT = 'details',
  WORK_ORDER = 'details',
  CUSTOMERS = 'details',
  SHIPPING = 'details',
}
