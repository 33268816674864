import { createStyles, Theme } from '@material-ui/core';
import Colors from '../../../../../layout/theme/utils/colors';

export default (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
    },
    submitButton: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: '5px 15px',
      position: 'static',
      width: '92px',
      height: '25px',
      left: 'calc(50% - 92px/2 + 139px)',
      top: 'calc(50% - 25px/2)',
      background: Colors.navy,
      borderRadius: '200px',
      flex: 'none',
      order: 1,
      flexGrow: 0,
      margin: '0px 20px',
      float: 'right',
      clear: 'right',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '15px',
      color: Colors.white,
    },
    cancelButton: {
      float: 'right',
      marginRight: theme.spacing(1),
      position: 'static',
      width: '43px',
      height: '25px',
      left: 'calc(50% - 43px/2 + 51.5px)',
      top: 'calc(50% - 15px/2)',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '15px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'right',
      color: Colors.almost_black,
      flex: 'none',
      order: 0,
      flexGrow: 0,
      margin: '0px 20px',
    },
  });
