/* eslint-disable max-len */
import { CHANGE_REQUEST_LIST_URL, DOCUMENT_REVISION_LIST_URL, DOCUMENT_TYPE_MANAGEMENT_URL, HOME_URL, PAGE_DETAILS_MANAGEMENT_URL } from '../../../ui/constants/urls';
import { GroupTag } from '../auth/types';
import { NavigationItem } from './types';

export const SELECT_COMPANY = 'company/SELECT_COMPANY';
export const SET_COMPANY_MINE = 'company/SET_COMPANY_MINE';
export const UPDATE_NAVIGATION_ITEM = 'company/UPDATE_NAVIGATION_ITEM';
export const AUTOSAVE_CONFIG = 'company/AUTOSAVE_CONFIG';
export const SET_AUTOSAVE_CONFIG = 'company/SET_AUTOSAVE_CONFIG';
export const REDLINE_CONFIG = 'company/REDLINE_CONFIG';
export const SET_GENERAL_SETTINGS = 'company/SET_GENERAL_SETTINGS';
export const SET_SUBSCRIPTIONS = 'company/SET_SUBSCRIPTIONS';
export const UPDATE_SUBSCRIPTION = 'company/UPDATE_SUBSCRIPTION';

export const BULK_MIGRATION_GET_DOCUMENT_TYPES = 'bulk_migration/getDocumentTypes';
export const BULK_MIGRATION_GET_DOCUMENT_TYPE_FORM_DETAILS = 'bulk_migration/getDocumentTypeFormDetails';
export const BULK_MIGRATION_GET_ELIGIBLE_REVS = 'bulk_migration/getEligibleRevs';
export const BULK_MIGRATION_GET_APPROVAL_REQUIREMENTS = 'bulk_migration/getDocumentTypeApprovalRequirements';
export const BULK_MIGRATION_ADD_USER_TO_GROUPS = 'bulk_migration/approvalRequirements/addUserToGroups';
export const BULK_MIGRATION_GET_SCAN_DETAILS = 'bulk_migration/getScanDetails';
export const BULK_MIGRATION_REGISTER_SCAN = 'bulk_migration/registerScan';
export const BULK_MIGRATION_UPDATE_SCAN = 'bulk_migration/updateScan';
export const BULK_MIGRATION_CANCEL_SCAN = 'bulk_migration/cancelScan';
export const BULK_MIGRATION_REGISTER_SCAN_FOR_RUN = 'bulk_migration/registerScanForRun';
export const BULK_MIGRATION_GET_SCAN_REVISIONS_FOR_PREVIEW = 'bulk_migration/getScanRevisionsForPreview';
export const BULK_MIGRATION_GET_REVISION_MAPPING_PREVIEW = 'bulk_migration/previewMapping';
export const BULK_MIGRATION_GET_SCAN_RESULTS = 'bulk_migration/getScanRevisions';
export const BULK_MIGRATION_START_REVS_MIGRATION = 'bulk_migration/migrateDocRevs';

export const ANNOUNCEMENTS_BASE = 'announcementHub';
export const ANNOUNCEMENTS_GET_DETAILS = 'announcementHub/details';

export const WHITE_LABEL_DEFAULT = {
  logo: '',
  avatar: '',
  colors: {
    defaultBackground: '',
    primaryContrastText: '',
    primary: '',
    secondary: '',
    secondaryContrastText: '',
  },
};

export const DEFAULT_NAVIGATION_ITEMS: NavigationItem[] = [
  {
    label: 'Dashboard',
    type: 'LINK',
    path: HOME_URL,
    icon: '/navigation_icons/dashboard.svg',
  },
  {
    label: 'Document Control',
    type: 'MENU',
    icon: '/navigation_icons/document-menu.svg',
    isOpen: false,
    menu: [
      {
        label: 'Document List',
        type: 'LINK',
        icon: '/navigation_icons/document.svg',
        path: DOCUMENT_REVISION_LIST_URL,
      },
      {
        label: 'Approval Requests',
        type: 'LINK',
        path: CHANGE_REQUEST_LIST_URL,
        icon: '/navigation_icons/cr.svg',
      },
      {
        label: 'Records',
        type: 'CATEGORY',
        category: 'Record',
        icon: '/navigation_icons/text-paper.svg',
      },
    ],
  },
  {
    label: 'Equipment',
    type: 'CATEGORY',
    category: 'Equipment',
    icon: '/navigation_icons/document-menu.svg',
  },
  {
    label: 'Purchase Orders',
    type: 'CATEGORY',
    icon: '/navigation_icons/receipt_list.svg',
    category: 'Purchase Order',
    restrictedToUsersWithTags: [GroupTag.CREATE_DOCS_OF_RESTRICTED_DOC_TYPES],
  },
  {
    label: 'Supplier Management',
    type: 'MENU',
    icon: '/navigation_icons/supplier-menu.svg',
    menu: [
      {
        icon: '/navigation_icons/supplier-list.svg',
        type: 'CATEGORY',
        label: 'Supplier List',
        category: 'Supplier',
        restrictedToUsersWithTags: [
          GroupTag.CREATE_DOCS_OF_RESTRICTED_DOC_TYPES,
        ],
      },
    ],
    restrictedToUsersWithTags: [GroupTag.CREATE_DOCS_OF_RESTRICTED_DOC_TYPES],
  },
  {
    label: 'Receiving',
    type: 'CATEGORY',
    icon: '/navigation_icons/cart-simple-in.svg',
    category: 'Receive',
    restrictedToUsersWithTags: [GroupTag.CREATE_DOCS_OF_RESTRICTED_DOC_TYPES],
  },
  {
    label: 'Inspection',
    type: 'CATEGORY',
    icon: '/navigation_icons/receipt_list.svg',
    category: 'Lot Inspection',
    restrictedToUsersWithTags: [GroupTag.CREATE_DOCS_OF_RESTRICTED_DOC_TYPES],
  },
  {
    label: 'Manufacturing',
    type: 'MENU',
    icon: '/navigation_icons/tools.svg',
    isOpen: false,
    menu: [
      {
        label: 'Lot/Inventory',
        type: 'CATEGORY',
        icon: '/navigation_icons/tag-check.svg',
        category: 'Lot',
        restrictedToUsersWithTags: [GroupTag.CREATE_DOCS_OF_RESTRICTED_DOC_TYPES],
      },
      {
        label: 'Parts',
        type: 'CATEGORY',
        icon: '/navigation_icons/tag-check.svg',
        category: 'Parts',
      },
    ],
    restrictedToUsersWithTags: [GroupTag.CREATE_DOCS_OF_RESTRICTED_DOC_TYPES],
  },
  {
    label: 'Document Type Management',
    type: 'LINK',
    path: DOCUMENT_TYPE_MANAGEMENT_URL,
    icon: '/navigation_icons/document.svg',
    restrictedToUsersWithTags: [GroupTag.DOCUMENT_TYPE_MANAGEMENT],
  },
  {
    label: 'Page Details Management',
    type: 'LINK',
    path: PAGE_DETAILS_MANAGEMENT_URL,
    icon: '/navigation_icons/receipt_list.svg',
    restrictedToUsersWithTags: [GroupTag.PAGE_DETAILS_MANAGEMENT],
  },
  {
    icon: '/navigation_icons/document-menu.svg',
    label: 'Async Tasks',
    path: '/async_tasks',
    type: 'LINK',
    restrictedToUsersWithTags: [GroupTag.ASYNC_TASKS_UI],
  },
];

export const OMIT_FIELDS = ['id', 'materialFlowACLs', 'statusCode'];
