import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Divider,
  Typography,
} from '@material-ui/core';
import { History } from 'history';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { translate } from '../../../../common/intl';
import { MomentFormats } from '../../../../common/utils/date';
import { deleteBulkImportSuccess, getReportsExcelFile, patchBulkImport, setBulkImport, setBulkImportsStatus, setCurrentStep } from '../../../../state/ducks/bulkImport/actions';
import { getBulkImportsGroupByProcess } from '../../../../state/ducks/bulkImport/selectors';
import { BkStatus, BulkImportCustom } from '../../../../state/ducks/bulkImport/types';
import { ChangeRequestTransitionResponse } from '../../../../state/ducks/changeRequest/types';
import { ApprovalStatus } from '../../../../state/ducks/common/types';
import Text from '../../../components/Text';
import { Button } from '../../../components/forms/fields-next';
import { BULK_IMPORT_EDIT_BASE_URL } from '../../../constants/urls';
import { StyleTooltip } from '../../../dashboard.new/line.items/common/StyleTooltip';
import ApproveDialogueContainer from '../../../dashboard.new/line.items/purchase.order/task.approval/POApprovalDialogContainer';
import useActionCreator from '../../../hooks/useActionCreator';
import useAsync from '../../../hooks/useAsync';
import useDialog from '../../../hooks/useDialog';
import DeleteDialog from '../Dialogs/DeleteDialog';
import MessageBox from './ErrorMessage';
import ProgressIcons from './ProgressIcons';
import useStyles, { CustomLinearProgress } from './styles';

interface ProgressItemProps {
  item: BulkImportCustom
  idx: number
  valKey: string
  currentEmployeeId: string
}

const STATUSES_HIDE_DELETE = [BkStatus.IN_PROGRESS, BkStatus.COMPLETED, BkStatus.SUCCESS, BkStatus.CANCELLED];
const STATUSES_COMPLETE = [BkStatus.COMPLETED, BkStatus.SUCCESS];

const ProgressItem: React.FC<ProgressItemProps> = ({ item, idx, valKey, currentEmployeeId }) => {
  const classes = useStyles();
  const history = useHistory() as History;
  const dispatch = useDispatch();
  const bulkImportsGroupByProcess = useSelector(getBulkImportsGroupByProcess);
  const deleteBulkImportAction = useActionCreator(patchBulkImport);
  const currentApproval = item?.documentRevision?.approvals.find(
    approval => approval.status === ApprovalStatus.Pending && approval.approverId === currentEmployeeId,
  );
  const transitionDialog = useDialog();
  const transitionDialog1 = useDialog();
  const isProcessEmpty = isEmpty(item.process?.process);
  const isReportEmpty = isEmpty(item?.reports);
  const showApproverButtons = currentApproval?.approverId && currentApproval?.approverId === currentEmployeeId && isProcessEmpty && item.status === BkStatus.STARTED;
  const showApprovals = !!currentApproval && showApproverButtons;
  const isComplete = STATUSES_COMPLETE.includes(item?.status as BkStatus) && item?.completedAt;
  const isRejected = item?.status === BkStatus.CANCELLED;
  const isFailed = item?.status === BkStatus.FAILED;
  const showErrorMessage = isRejected || isFailed;
  const showDeleteButton = isProcessEmpty && !STATUSES_HIDE_DELETE.includes(item?.status as BkStatus);
  const showButtonView = ((!isRejected && item.currentProgressStep > 0 && !item?.completedAt) || (item?.reports) || item.status === BkStatus.CANCELLED);
  const totalExcelErrors = Number(item?.process?.totalExcelErrors ?? 0);
  const isWarning = !showErrorMessage && !(item?.attachmentId || Number(item.process?.step) <= 3) && totalExcelErrors >= 1;
  const openDeleteDialog = useDialog();

  const async = useAsync<ChangeRequestTransitionResponse>({
    onSuccess: () => {
      transitionDialog.close();
    },
    onError: () => {
      transitionDialog.close();
    },
  });
  const asyncDeleteBk = useAsync({
    onSuccess: () => {
      handleCloseDelete();
    },
  });

  const openApproveDialog = () => {
    async.reset();
    transitionDialog.open();
  };

  const openRejectDialog = () => {
    async.reset();
    transitionDialog1.open();
  };

  const handleApproveClick = () => {
    openApproveDialog();
  };

  const handleRejectClick = () => {
    openRejectDialog();
  };

  const handleOpenDetele = () => {
    openDeleteDialog.open();
  };
  const handleCloseDelete = () => {
    openDeleteDialog.close();
  };

  const afterDeleteBk = (bulkImportId: string) => {
    dispatch(deleteBulkImportSuccess(bulkImportId));
  };

  const handleDeleteClick = () => {
    asyncDeleteBk.start(deleteBulkImportAction, { bulkImportId: item.id, data: { status: BkStatus.DELETED }, callback: afterDeleteBk }, asyncDeleteBk);
  };

  const handleDownloadFile = () => {
    dispatch(getReportsExcelFile(item.id, item.jobId));
  };

  const handleReturnToStep = () => {
    dispatch(setBulkImport(item));
    // if the process is not empty is because is importing
    dispatch(setCurrentStep(item.currentProgressStep));
    history.push(`${BULK_IMPORT_EDIT_BASE_URL}${item.id}`);
  };

  const handleRejectedBulkImport = () => {
    dispatch(setBulkImportsStatus(item.id, BkStatus.CANCELLED));
  };

  const handleApproveBulkImport = () => {
    dispatch(setBulkImportsStatus(item.id, BkStatus.IN_PROGRESS));
  };

  return (
    <React.Fragment key={idx}>
      <Box className={classes.itemContainer}>
        <Box>
          <Box className={classes.titleContainer}>
            {isComplete ? (
              <FontAwesomeIcon
                className={classes.checkGreenIcon}
                icon={solid('circle-check')}
              />
            ) : isFailed || isWarning ? (
              <FontAwesomeIcon
                className={classes.errorRedIcon}
                icon={regular('triangle-exclamation')}
              />
            ) : (
              <FontAwesomeIcon
                className={classes.watchLaterIcon}
                icon={solid('clock-three')}
              />
            )}
            <Typography className={classes.itemTitle}>
              {item.jobId}
            </Typography>
          </Box>
          {(showErrorMessage) && (
            <MessageBox message={isRejected ? translate('bulkImport.approval.rejected.error') : translate('bulkImport.approval.failed.error')} />
          )}
          {(isWarning) && (
            <MessageBox message={translate('bulkImport.approval.warning.error', { count: totalExcelErrors })} action={translate('bulkImport.approval.view.error')} callback={handleReturnToStep} />
          )}
        </Box>
        <Box className={classes.progressBarIconsContainer}>
          {isComplete ? (
            <Box className={classes.completedMsgContainer}>
              <FontAwesomeIcon
                className={classes.checkGreenIcon}
                icon={solid('circle-check')}
              />
              <Typography className={classes.completedText}>
                {translate('common.completed')} ({ item.completedAt ? moment(item.completedAt).format(MomentFormats.FullDateTime) : ''})
              </Typography>
            </Box>
          ) : isFailed ? (
            <Box className={classes.completedMsgContainer}>
              <FontAwesomeIcon
                className={classes.errorRedIcon}
                icon={solid('triangle-exclamation')}
              />
              <Typography className={classes.completedText}>
                {translate('common.failed')} ({ item.completedAt ? moment(item.completedAt).format(MomentFormats.FullDateTime) : ''})
              </Typography>
            </Box>
          ) : (
            <>
              <ProgressIcons item={item} />
              {item?.status === BkStatus.IN_PROGRESS && <Box className={classes.progressBarContainer}>
                <Typography className={classes.progressBarTitle}>
                  {translate('common.importingFiles')}
                </Typography>
                <CustomLinearProgress variant="determinate" value={item.progress} className={classes.progressBar} />
                <Typography className={classes.progressBarText}>
                  {`${item.progress}% completed`}
                </Typography>
              </Box>}
            </>
          )}
        </Box>
        <Box className={classes.viewModalContainer}>
          {showApprovals && <Box className={classes.approvalsContainer}>
            <StyleTooltip
              title={<Text translation="common.reject" />}
              placement="top"
              arrow
            >
              <FontAwesomeIcon
                data-cy="transition-reject"
                onClick={handleRejectClick}
                className={classes.rejectIcon}
                icon={regular('xmark-to-slot')}
              />
            </StyleTooltip>
            <StyleTooltip
              title={<Text translation="common.approve" />}
              placement="top"
              arrow
            >
              <FontAwesomeIcon
                data-cy="transition-approve"
                onClick={handleApproveClick}
                className={classes.approveIcon}
                icon={regular('check-to-slot')}
              />
            </StyleTooltip>
          </Box>}
          {showButtonView && <Button className={isReportEmpty || item.status === BkStatus.CANCELLED ? classes.viewButton : classes.downloadLogs} kind="outlined" color={!isReportEmpty ? 'primary' : 'inherit'} onClick={isReportEmpty ? handleReturnToStep : handleDownloadFile}>
            <Text translation={isReportEmpty || item.status === BkStatus.CANCELLED ? 'bulkImport.progress.view' : 'bulkImport.progress.downloadLogs' } />
          </Button>}
          {isReportEmpty && <Box width={29}>
            {showDeleteButton && <StyleTooltip
              title={<Text translation="bulkImport.delete" />}
              placement="top"
              arrow
            >
              <FontAwesomeIcon
                onClick={handleOpenDetele}
                className={classes.deleteIcon}
                icon={regular('trash-can')}
              />
            </StyleTooltip>}
          </Box>}
        </Box>
      </Box>
      {idx !== bulkImportsGroupByProcess[valKey].length - 1 && <Divider className={classes.divider} />}
      {showApproverButtons
        && <>
          <ApproveDialogueContainer
            transition="reject"
            type="reject"
            approvalId={currentApproval?.id}
            transitionDialog={transitionDialog1}
            label="transition.action.reject"
            translationRejectMsg="bulkImport.reject.alert"
            onSuccess={handleRejectedBulkImport}
          />
          <ApproveDialogueContainer
            transition="approve"
            approvalId={currentApproval?.id}
            transitionDialog={transitionDialog}
            label="transition.action.approve"
            onSuccess={handleApproveBulkImport}
          />
        </>
      }
      <DeleteDialog handler={openDeleteDialog} handleClose={handleCloseDelete} handleConfirm={handleDeleteClick} />
    </React.Fragment>
  );
};

export default ProgressItem;
