import { cloneDeep, filter, find, findIndex, includes, inRange, isUndefined, size, union, unionBy } from 'lodash';
import { action, get, observable, set } from 'mobx';
import React from 'react';
import { FBActionField, FBApproval, FBAutocompleteAsync, FBCheckboxGroup, FBEditorState, FBInputProps, FBInputType, FBSchemaProps } from '..';
import FBTasksListTable from '../../change.request/FBMaterialDisposition/components/FBTasksListTable';
import FBApprovalMatrixField from '../FBApprovalMatrixField/FBApprovalMatrixField';
import FBApprovalMatrixFieldRoles from '../FBApprovalMatrixField/FBApprovalMatrixFieldRoles';
import FBApprovals from '../FBApprovals/FBApprovals';
import FBApprovers from '../FBApprovers/FBApprovers';
import FBBlankTplForm from '../FBBlankTplForm/FBBlankTplForm';
import FBButton from '../FBButton/FBButton';
import FBCertifications from '../FBCertifications';
import FBCheckbox from '../FBCheckbox/FBCheckbox';
import FBCheckboxGroupAsync from '../FBCheckboxGroupAsync/FBCheckboxGroupAsync';
import FBCustomerHeaderQuaterAddress from '../FBCustomerHeaderQuaterAddress/FBCustomerHeaderQuaterAddress';
import FBCycleCount from '../FBCycleCount';
import FBDatePicker from '../FBDatePicker/FBDatePicker';
import FBDocumentRevisions from '../FBDocumentRevisions/FBDocumentRevisions';
import FBDownloadSheetTpl from '../FBDownloadSheetTpl/FBDownloadSheetTpl';
import FBEBPart from '../FBEBPart/FBEBPart';
import FBEQMaintenance from '../FBEQMaintenance/FBEQMaintenance';
import FBFileUpload from '../FBFileUpload/FBFileUpload';
import FBHelloSign from '../FBHelloSign/FBHelloSign';
import FBHyperlink from '../FBHyperlink/FBHyperlink';
import FBInlineApproval from '../FBInlineApproval/FBInlineApproval';
import FBInstructionsDocument from '../FBInstructionsDocument/FBInstructionsDocument';
import FBLHRBuild from '../FBLHRBuild/FBLHRBuild';
import FBLHRStep from '../FBLHRStep/FBLHRStep';
import FBLotOnHandQuantityField from '../FBLotOnHandQuantityField/FBLotOnHandQuantityField';
import FBLotQuantityField from '../FBLotQuantityField/FBLotQuantityField';
import FBLotTransfers from '../FBLotTransfers';
import FBMediaAlbumField from '../FBMediaAlbumField/FBMediaAlbumField';
import FBMPIProcedure from '../FBMPIProcedure/FBMPIProcedure';
import FBNumericField from '../FBNumericField/FBNumericField';
import FBParagraph from '../FBParagraph/FBParagraph';
import FBParts from '../FBParts/FBParts';
import FBPartsSelection from '../FBPartsSelection/FBPartsSelection';
import FBPartVendors from '../FBPartVendors';
import FBPOApprovalMatrix from '../FBPOApprovalMatrix/FBPOApprovalMatrix';
import FBPOApprovals from '../FBPOApprovals/FBPOApprovals';
import FBPOReceive from '../FBPOReceive/FBPOReceive';
import FBProcedure from '../FBProcedure/FBProcedure';
import FBProcedureDiff from '../FBProcedureDiff/FBProcedureDiff';
import FBProductOrService from '../FBProductOrService';
import FBPurchaseOrder from '../FBPurchaseOrder/FBPurchaseOrder';
import FBPurchaseOrderSummary from '../FBPurchaseOrder/FBPurchaseOrderSummary';
import FBQuantityField from '../FBQuantityField/FBQuantityField';
import FBQuantityUnitField from '../FBQuantityUnitField/FBQuantityUnitField';
import FBRadio from '../FBRadio/FBRadio';
import FBRadioGroup from '../FBRadioGroup/FBRadioGroup';
import FBReferences from '../FBReferences';
import FBRequestApproval from '../FBRequestApproval/FBRequestApproval';
import FBSchemaSelect from '../FBSchemaSelect/FBSchemaSelect';
import FBSection from '../FBSection/FBSection';
import FBSelect from '../FBSelect/FBSelect';
import FBSelectDocument from '../FBSelectDocument/FBSelectDocument';
import FBShippingAddress from '../FBShippingInfo/FBShippingAddress';
import FBShippingItems from '../FBShippingItems';
import FBSiteInformation from '../FBSiteInformation';
import FBSubsection from '../FBSubsection/FBSubsection';
import FBSupplier from '../FBSupplier/FBSupplier';
import FBSupplierContacts from '../FBSupplierContacts';
import FBTask from '../FBTask/FBTask';
import FBTextEditor from '../FBTextEditor/FBTextEditor';
import FBTextField from '../FBTextField/FBTextField';
import FBTextFieldWithRange from '../FBTextFieldWithRange/FBTextFieldWithRange';
import FBTimerField from '../FBTimerField/FBTimerField';
import FBTraineeList from '../FBTraineeList';
import FBUnitMeasures from '../FBUnitMeasures/FBUnitMeasures';
import FBWOApprovals from '../FBWOApprovals/FBWOApprovals';
import FBWorkOrderLHRTable from '../FBWorkOrderLHRTable/FBWorkOrderLHRTable';
import FBWorkOrderPartDetails from '../FBWorkOrderPartDetails/FBWorkOrderPartDetails';
import FBWorkOrderType from '../FBWorkOrderType/FBWorkOrderType';
import FBZIPUpload from '../FBZIPUpload/FBZIPUpload';

class FBSchemaState {
  public static omitLabel: FBInputType[] = [
    'section',
    'subsection',
    'stepsection',
    'teststep',
    'purchaseorder',
    'task',
    'blanktplform',
    'approvals',
    'eqmaintenance',
    'lhrstep',
  ];

  static readonly control: Partial<Record<FBInputType, React.FunctionComponent<any>>> = {
    section: FBSection,
    subsection: FBSubsection,
    stepsection: FBSection,
    teststep: FBSection,
    textfield: FBTextField,
    checkbox: FBCheckbox,
    checkboxgroup: FBCheckboxGroup,
    checkboxgroupasync: FBCheckboxGroupAsync,
    autocomplete: FBAutocompleteAsync,
    autocompleteasync: FBAutocompleteAsync,
    texteditor: FBTextEditor,
    datepicker: FBDatePicker,
    hyperlink: FBHyperlink,
    select: FBSelect,
    radio: FBRadio,
    radiogroup: FBRadioGroup,
    fileupload: FBFileUpload,
    zipattachment: FBZIPUpload,
    blanktplform: FBBlankTplForm,
    schemaselect: FBSchemaSelect,
    paragraph: FBParagraph,
    quantity: FBQuantityField,
    timer: FBTimerField,
    traineeList: FBTraineeList,
    purchaseorder: FBPurchaseOrder,
    purchaseordersummary: FBPurchaseOrderSummary,
    headquarterAddress: FBCustomerHeaderQuaterAddress,
    siteInformations: FBSiteInformation,
    poreceive: FBPOReceive,
    documentRevisions: FBDocumentRevisions,
    instructionsmediaupload: FBMediaAlbumField,
    evidencemediaupload: FBMediaAlbumField,
    instructionsdocument: FBInstructionsDocument,
    selectdocument: FBSelectDocument,
    checkpoint: FBButton,
    createlabel: FBButton,
    approval: FBApproval,
    inlineapproval: FBInlineApproval,
    requestapproval: FBRequestApproval,
    action: FBActionField,
    hellosign: FBHelloSign,
    approvalMatrix: FBApprovalMatrixField,
    approvalRoles: FBApprovalMatrixFieldRoles,
    poApprovalMatrix: FBPOApprovalMatrix,
    task: FBTask,
    approvers: FBApprovers,
    approvals: FBApprovals,
    woApprovals: FBWOApprovals,
    procedure: FBProcedure,
    mpiprocedure: FBMPIProcedure,
    parts: FBParts,
    procedurediff: FBProcedureDiff,
    ebpart: FBEBPart,
    eqmaintenance: FBEQMaintenance,
    textfieldwithrange: FBTextFieldWithRange,
    supplier: FBSupplier,
    lhrstep: FBLHRStep,
    unitMeasures: FBUnitMeasures,
    quantityunit: FBQuantityUnitField,
    partsselection: FBPartsSelection,
    poapprovals: FBPOApprovals,
    lhrbuild: FBLHRBuild,
    downloadsheet: FBDownloadSheetTpl,
    lotTransfers: FBLotTransfers,
    lotQuantity: FBLotQuantityField,
    lotOnHandQuantity: FBLotOnHandQuantityField,
    partVendors: FBPartVendors,
    numeric: FBNumericField,
    cycleCount: FBCycleCount,
    workOrderPartDetails: FBWorkOrderPartDetails,
    tasksListTable: FBTasksListTable,
    workOrderType: FBWorkOrderType,
    workOrderLhrTable: FBWorkOrderLHRTable,
    shippingItems: FBShippingItems,
    supplierContacts: FBSupplierContacts,
    supplierOfferingList: FBProductOrService,
    supplierSupportingDocs: FBCertifications,
    supplierReferences: FBReferences,
    shippingAddress: FBShippingAddress,
  };

  @observable public schema?: FBSchemaProps[];
  public validationSchema?: Array<Partial<FBSchemaProps>>;

  public constructor (schema?: FBSchemaProps[]) {
    this.schema = schema;
  }

  public static component = (controlProps: FBInputProps): React.ReactNode | undefined => {
    const { type, name, index } = controlProps;
    const schemaControl = FBSchemaState.control[type || ''];
    if (!schemaControl) {
      return;
    }
    return React.createElement(schemaControl, {
      key: `fb-control-${type}-${name}-${index}`,
      ...controlProps,
    });
  };

  public setValidationSchemaItem = (item: Partial<FBSchemaProps>) => {
    if (!item.name || !item.label) { return; }
    set(this, 'validationSchema', unionBy(this.validationSchema || [], [item], 'name'));
  };

  public getValidationSchema = () => cloneDeep(get(this, 'validationSchema'));

  @action public getSchema = (): FBSchemaProps[] => cloneDeep(get(this, 'schema'));

  @action public getSchemaItem = (name?: string): FBSchemaProps | undefined => {
    if (!name) {
      return;
    }
    return find(this.getSchema(), { name });
  };

  @action public getSchemaItemAt = (index?: number): FBSchemaProps | undefined => {
    if (isUndefined(index) || !inRange(index, 0, this.schema?.length)) {
      return;
    }
    return this.schema && this.schema[index];
  };

  @action public initSchema = (schema?: FBSchemaProps[]): void => {
    if (schema) {
      schema
        .sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
        .forEach((item, index) => {
          item.index = index;
        });
    }
    set(this, 'schema', schema);
  };

  @action public setSchema = (schema?: FBSchemaProps[]) => {
    schema = (schema || []).map((schemaItem: FBSchemaProps, i, ro) => ({
      ...schemaItem,
      index: i,
      ...(includes(FBEditorState.pickStepIndex, schemaItem.type)) && {
        stepIndex: size(filter(ro.slice(0, i + 1), (step) =>
          !step.deleted && includes(FBEditorState.pickStepIndex, step.type))),
      },
    }));
    set(this, 'schema', schema);
  };

  @action public setSchemaItem = (schemaItem: FBSchemaProps) => {
    const schemaItemIndex = findIndex(this.getSchema(), { name: schemaItem.name });
    const schemaAt = this.getSchemaItemAt(schemaItemIndex);
    if (!schemaAt) {
      const schema = union(this.schema, [schemaItem]);
      this.setSchema(schema);
      return;
    }
    this.updateSchemaItem(schemaItem, schemaItemIndex);
    this.setValidationSchemaItem(schemaItem);
  };

  @action public setSchemaItemAt = (schemaItem: FBSchemaProps | FBInputProps, index = 0) => {
    const schemaAt = this.getSchemaItemAt(index);
    if (schemaAt && schemaItem.name === schemaAt.name) {
      return this.updateSchemaItem(schemaItem, index);
    }
    const newSchema = this.schema?.slice(0) || [];
    newSchema?.splice(index, 0, schemaItem);
    this.setSchema(newSchema);
    this.setValidationSchemaItem(schemaItem);
  };

  @action public updateSchemaItem = (schemaItem: FBSchemaProps, at: number) => {
    const newSchema = [...this.getSchema()];
    newSchema[at] = schemaItem;
    this.setSchema(newSchema);
    this.setValidationSchemaItem(schemaItem);
  };

  @action public removeSchemaItem = (schemaItem?: FBSchemaProps) => {
    if (!schemaItem) {
      return;
    }
    const schema = filter(this.schema, (item) => item.name !== schemaItem.name);
    this.setSchema(schema);
    const validationSchema = filter(this.schema, (item) => item.name !== schemaItem.name);
    this.validationSchema = validationSchema;
  };

  @action public removeSchemaItemAt = (index: number | undefined) => {
    const schemaItem = this.getSchemaItemAt(index);
    if (isUndefined(schemaItem)) {
      return;
    }
    this.removeSchemaItem(schemaItem);
  };

  public getItemsByType = (type?: FBInputType): FBSchemaProps[] => {
    if (!type) { return []; }
    return filter(this.getSchema(), (item) =>
      !item.deleted && item.type === type,
    );
  };
}

export default FBSchemaState;
